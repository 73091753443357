<template>
  <div class="common_header">
    <div class="div-header">
      <div class="home_page_header">
        <router-link to='/' class="header_page_img"></router-link>
        <div class="header_page_nav">
          <div class="home_page_header_nav" v-for="items in navList" :key="items.id">
            <router-link :to="items.src" :class="navActive === items.id ? 'nav-active' : 'nav-item'">{{items.title}}</router-link>
            <div class="nav_children" v-show="items.id === 2">
              <router-link class="nav_children_single" to="/software/smartCity" :class="navCount === 1 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="navOne()">智慧城市</router-link>
              <router-link class="nav_children_single" to="/software/Industrial" :class="navCount === 2 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="navTow()">工业物联网</router-link>
              <router-link class="nav_children_single" to="/software/supplychain" :class="navCount === 3 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="navThree()">供应链金融</router-link>
              <router-link class="nav_children_single" to="/software/blockchain" :class="navCount === 4 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="navFour()">区块链</router-link>
            </div>
            <div class="nav_children" v-show="items.id === 5">
              <router-link class="nav_children_single" to="/aboutZC/company" :class="count === 1 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="activeOne()">公司简介</router-link>
              <router-link class="nav_children_single" to="/aboutZC/contact" :class="count === 2 ? 'nav_children_single_active' : 'nav_children_single'" @click.native="activeTwo()">联系紫传</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'HomeView',
  props: {
    navActive: {
      type: Number,
      default: 1
    },
    navChrActive: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // 搜索框的值
      searchVal: '',
      flag: false,
      // 头部nav的对象
      navList: [
        {
          title: '官网首页',
          src: '/',
          id: 1
        },
        {
          title: '软件开发',
          src: '/software',
          id: 2
        },
        {
          title: '人力资源',
          src: '/human',
          id: 3
        },
        {
          title: '客户案例',
          src: '/customer',
          id: 4
        },
        {
          title: '关于紫传',
          src: '/aboutZC',
          id: 5
        }
      ]
    }
  },
  created () {
    this.$store.commit('about', 0)
    this.$store.commit('software', 0)
  },
  computed: {
    ...mapState(['count', 'navCount'])// 展开运算符
  },
  methods: {
    activeOne () {
      this.$store.commit('about', 1)
    },
    activeTwo () {
      this.$store.commit('about', 2)
    },
    navOne () {
      this.$store.commit('software', 1)
    },
    navTow () {
      this.$store.commit('software', 2)
    },
    navThree () {
      this.$store.commit('software', 3)
    },
    navFour () {
      this.$store.commit('software', 4)
    }
  }
}
</script>
<style lang="less" scoped>
.common_header {
  width: 100%;
  min-width: 1400px;
  border-bottom: 1px solid #fff;
  .div-header {
    width: 1400px;
    height: 99px;
    margin: auto;
    .home_page_header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header_page_img {
        display: inline-block;
        width: 230px;
        height: 56px;
        background: url('~@/assets/img/logo.png') no-repeat left center;
      }
      .header_page_nav {
        display: flex;
        width: 920px;
        justify-content: space-between;
        .home_page_header_nav {
          position: relative;
          width: 120px;
          line-height: 99px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-stretch: normal;
          letter-spacing: 1px;
          color: #ffffff;
          text-align: center;
          .nav-item {
            display: block;
            color: #fff;
            font-size: 18px;
            outline: none;
            cursor: pointer;
            &:after {
              content: '';
              left: 50%;
              right: 50%;
            }
            &:hover {
              &:after{
                content: '';
                display: block;
                height: 2px;
                position: absolute;
                bottom: 0px;
                left: 50%;
                right: 50%;
                background: #fff;
                transition: all .3s ease-out;
              }
              &:hover{
                &:after {
                  left: 22.5px;
                  right: 22.5px;
                }
              }
            }
          }
          .nav-active {
            display: block;
            color: #fff;
            font-size: 18px;
            outline: none;
            cursor: pointer;
            &:before {
              content: '';
              display: block;
              position: absolute;
              bottom: 0px;
              left: 22.5px;
              right: 22.5px;
              height: 2px;
              background-color: #fff;
            }
          }
          .nav_children{
            width: 100%;
            padding-bottom: 20px;
            position: absolute;
            color: #666666;
            display: none;
            background-color: #fff;
            font-size: 14px;
            .nav_children_single {
              display: block;
              height: 40px;
              line-height: 5;
            }
            .nav_children_single_active {
              display: block;
              height: 40px;
              line-height: 5;
              color: #005bff;
            }
            .nav_children_single:hover {
              color: #005bff;
            }
          }
        }
        .home_page_header_nav:nth-child(2):hover{
          .nav_children{
            display: block;
          }
        }
        .home_page_header_nav:nth-child(5):hover{
          .nav_children{
            display: block;
          }
        }
      }
    }
  }
}
</style>
