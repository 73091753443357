<template>
  <div class="foot">
    <div class="foot_box">
      <div class="foot_logo">
        <img src="@/assets/img/logo.png"/>
        <span>Copyright © 济南紫传网络科技有限公司</span>
      </div>
      <div class="foot_info">
        <div class="info_message">
          <div>公司电话：15554150617</div>
          <div>公司微信：liangnc_</div>
          <div>公司邮箱：1253788613@qq.com</div>
          <div>公司地址:济南市历城区舜泰广场10号楼11层</div>
        </div>
        <div class="info_number">
          <div>鲁ICP备20001132号</div>
        </div>
      </div>
      <div class="foot_table">
       <div class="foot_tab">
        <router-link class="link" to="/" @click.native="nav">官网首页</router-link><span>|</span>
        <router-link class="link" to="/software"  @click.native="nav">软件开发</router-link><span>|</span>
        <router-link class="link" to="/human"  @click.native="nav">人力资源</router-link><span>|</span>
        <router-link class="link" to="/customer"  @click.native="nav">客户案例</router-link><span>|</span>
        <router-link class="link" to="/aboutZC/contact"  @click.native="nav">联系我们</router-link>
       </div>
       <div class="foot_code">
        <img src="@/assets/foot_img/code.jpg"/>
        <img style="margin-left:70px" src="@/assets/foot_img/code02.jpg"/>
       </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {
    nav () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/var.less';
.foot{
  width: 100%;
  min-width: 1400px;
  height: 260px;
  margin-top: 60px;
  background-color: #333333;
  .foot_box{
    margin: 0 auto;
    width: 1400px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .foot_logo{
      width: 262px;
      height: 181px;
      display: flex;
      color: #fff;
       margin-top: 26px;
      justify-content: space-between;
      flex-direction: column;
      span{
        height: 16px;
        display: inline-block;
        margin: 0 auto;
        font-size: 14px;
      }
    }
    .foot_info{
      height: 180px;
      margin-left: 83px;
      color:#fff;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: space-between;
      margin-top: 30px;
      .info_message{
        display: flex;
        flex-direction: column;
        height: 122px;
        justify-content: space-between;
      }
    }
    .foot_table{
      width: 634px;
      height: 210px;
      color: #fff;
      margin-left: 35px;
      font-size: @text-Tab-Size;
      display: flex;
      justify-content: space-between;;
      flex-direction: column;
      .foot_tab{
        display: flex;
        justify-content: space-between;;
        span,.link{
          display: inline-block;
          height: 16px;
        }
      }
      .foot_tab :hover{
        color: #3179ff;
      }
      .foot_code{
        display: flex;
        justify-content: flex-end;
        img{
          width: 139px;
          height: 139px;
        }
      }
    }
  }
}
</style>
